import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import './pageStyles/terms-of-use.scss'

export default function TermsOfUse() {
  return (
    <Layout>
      <Seo title="Terms of Use" />
      <article id="post-129" className="post-129 page type-page status-publish hentry">
        <div className="entry-content">
          <div className="termsofuse-l et-l termsofuse-l--post et-l--post">
            <div className="termsofuse_builder_inner_content et_builder_inner_content termsofuse_pb_gutters3 et_pb_gutters3">
              <div className="termsofuse_pb_section et_pb_section termsofuse_pb_section_7 et_pb_section_7 termsofuse_pb_with_background et_pb_with_background termsofuse_section_regular et_section_regular section_has_divider termsofuse_pb_bottom_divider et_pb_bottom_divider termsofuse_pb_top_divider et_pb_top_divider optml-bg-lazyloaded termsofuse_pb_section_first et_pb_section_first" data-fix-page-container="on" style={{ paddingTop: '168px' }}>
                <div className="termsofuse_pb_row et_pb_row termsofuse_pb_row_8 et_pb_row_8 optml-bg-lazyloaded">
                  <div className="termsofuse_pb_column et_pb_column termsofuse_pb_column_4_4 et_pb_column_4_4 termsofuse_pb_column_11 et_pb_column_11  termsofuse_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough termsofuse-last-child et-last-child">
                    <div className="termsofuse_pb_module et_pb_module termsofuse_pb_text et_pb_text termsofuse_pb_text_8 et_pb_text_8 termsofuse_pb_text_align_center et_pb_text_align_center termsofuse_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                      <div className="termsofuse_pb_text_inner et_pb_text_inner">Terms of Use</div>
                    </div>
                  </div>
                </div>
                <div className="termsofuse_pb_bottom_inside_divider et_pb_bottom_inside_divider" style={{}} />
              </div>
              <div className="termsofuse_pb_section et_pb_section termsofuse_pb_section_8 et_pb_section_8 termsofuse_section_regular et_section_regular">
                <div className="termsofuse_pb_row et_pb_row termsofuse_pb_row_9 et_pb_row_9 optml-bg-lazyloaded">
                  <div className="termsofuse_pb_column et_pb_column termsofuse_pb_column_4_4 et_pb_column_4_4 termsofuse_pb_column_12 et_pb_column_12  termsofuse_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough termsofuse-last-child et-last-child">
                    <div className="termsofuse_pb_module et_pb_module termsofuse_pb_text et_pb_text termsofuse_pb_text_9 et_pb_text_9 termsofuse_pb_text_align_left et_pb_text_align_left termsofuse_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                      <div className="termsofuse_pb_text_inner et_pb_text_inner"><p>Welcome to our website. ARC Medical and its associates provide their services to you subject to the following conditions. If you visit this website, you accept these conditions. Please read them carefully.</p>
                        <h2>PRIVACY</h2>
                        <p>Please review our Privacy Notice, which also governs your visit to our website, to understand our practices.<br />ELECTRONIC COMMUNICATIONS<br />When you visit ARC Medical or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on this site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
                        <h2>COPYRIGHT</h2>
                        <p>All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of ARC Medical or its content suppliers and protected by international copyright laws. The compilation of all content on this site is the exclusive property of ARC Medical, with copyright authorship for this collection by ARC Medical, and protected by international copyright laws.</p>
                        <h2>TRADEMARKS</h2>
                        <p>ARC Medical's trademarks and trade dress may not be used in connection with any product or service that is not ARC Medical's, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits ARC Medical. All other trademarks not owned by ARC Medical or its subsidiaries that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by ARC Medical or its subsidiaries.</p>
                        <h2>LICENSE AND SITE ACCESS</h2>
                        <p>ARC Medical grants you a limited license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of ARC Medical. This license does not include any resale or commercial use of this site or its contents: any collection and use of any product listings, descriptions, or prices: any derivative use of this site or its contents: any downloading or copying of account information for the benefit of another merchant: or any use of data mining, robots, or similar data gathering and extraction tools. This site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of ARC Medical. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of ARC Medical and our associates without express written consent. You may not use any meta tags or any other "hidden text" utilizing ARC Medical's name or trademarks without the express written consent of ARC Medical. Any unauthorized use terminates the permission or license granted by ARC Medical. You are granted a limited, revocable, and nonexclusive right to create a hyperlink to the home page of ARC Medical so long as the link does not portray ARC Medical, its associates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any ARC Medical logo or other proprietary graphic or trademark as part of the link without express written permission.</p>
                        <h2>PRODUCT DESCRIPTIONS</h2>
                        <p>ARC Medical and its associates attempt to be as accurate as possible. However, ARC Medical does not warrant that product descriptions or other content of this site is accurate, complete, reliable, current, or error-free. If a product offered by ARC Medical itself is not as described, your sole remedy is to return it in unused condition.</p>
                        <h2>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h2>
                        <p>THIS SITE IS PROVIDED BY ARC MEDICAL ON AN "AS IS" AND "AS AVAILABLE" BASIS. ARC MEDICAL MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, ARC MEDICAL DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. ARC MEDICAL DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM ARC MEDICAL ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ARC MEDICAL WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.</p>
                        <h2>APPLICABLE LAW</h2>
                        <p>By visiting ARC Medical, you agree that the laws of the United States of America, without regard to principles of conflict of laws, will govern these Conditions of Use and any dispute of any sort that might arise between you and ARC Medical or its associates.</p>
                        <p>Any dispute relating in any way to your visit to ARC Medical or to products you purchase through ARC Medical shall be submitted to confidential arbitration in Georgia, USA except that, to the extent you have in any manner violated or threatened to violate ARC Medical's intellectual property rights, ARC Medical may seek injunctive or other appropriate relief in any state or federal court in the state of Georgia, USA, and you consent to exclusive jurisdiction and venue in such courts. Arbitration under this agreement shall be conducted under the rules then prevailing of the American Arbitration Association. The arbitrators award shall be binding and may be entered as a judgment in any court of competent jurisdiction. To the fullest extent permitted by applicable law, no arbitration under this Agreement shall be joined to an arbitration involving any other party subject to this Agreement, whether through class arbitration proceedings or otherwise.</p>
                        <h2>SITE POLICIES, MODIFICATION, AND SEVERABILITY</h2>
                        <p>Please review our other policies posted on this site. These policies also govern your visit to ARC Medical. We reserve the right to make changes to our site, policies, and these Conditions of Use at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.</p>
                        <h2>QUESTIONS</h2>
                        <p>Questions regarding our Conditions of Usage, Privacy Policy, or other policy related material can be directed to our support staff by clicking on the "Contact" link in the top menu.</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}